import React from 'react';
import { Link } from 'gatsby';

import BannerOrcamento from '../../../assets/images/banner-orcamento.png';

import './orcamento.scss';

const Orcamento = () => (
  <>
    <section id="budget" className="container">
      <div className="banner-budget">
        <img src={BannerOrcamento} alt="banner-orcamento" />
      </div>

      <div className="budget-text">
        <h1>Estamparia contínua em rolo</h1>
        <p>
          Temos o entendimento para atender de acordo com cada necessidade que
          os clientes apresentam. Contamos com equipamentos de alta performance
          que suportam grandes quantidades e tiragens, recomendado para
          confecções em quantidade, permitindo a otimização de custos de
          produção. Além disso, nossa equipe fornece suporte para detalhes
          importantes, como resolução de imagens e cálculo das dimensões,
          garantindo o uso máximo do tecido.
          <br />
          <br />
          Largura da calandra para entrada de tecido: 1,80 (máx.)
          <br />
          Pedido mínimo: 50 metros
          <br />
          Estampas variadas Arquivos: PSD/ JPEG
        </p>
        <Link to="/orcamento">Orçamento</Link>
      </div>
    </section>
  </>
);

export default Orcamento;
