import React from 'react';

import { SEO } from '../../components/seo';

import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import Contato from './contato';
import Orcamento from './orcamento';
import Orcamento2 from './orcamento2';
import AtendimentoExclusivo from './atendimento-exclusivo';

import '../../reset.css';

const EstampariaDigital = () => (
  <>
    <SEO title="Estamparia Digital" />
    <Header title="Estamparia Digital" />

    <Contato />
    <Orcamento />
    <Orcamento2 />
    <AtendimentoExclusivo />

    <Footer />
  </>
);

export default EstampariaDigital;
