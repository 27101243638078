import React from 'react';
import { Link } from 'gatsby';

import './contato.scss';

const Contato = () => (
  <>
    <section id="contact-us" className="container">
      <div className="text-us">
        <p>
          Nossa empresa é focada no trabalho de estamparia corrida através da
          sublimação, que é a transferência por calor da impressão em papel para
          o tecido. O método é eficaz em todos os tecidos cuja composição seja
          maior em poliéster. A aderência ofertada proporciona o tingimento
          total dos fios resultando em estampas extremamente lindas. Diferentes
          velocidades de impressão causam diferentes impactos nas imagens.
          Contamos com até 8 passadas por área, permitindo cores bem rígidas.
          <p className="block2">
            Aqui você conta com um atendimento exclusivo, aliando qualidade com
            os prazos que o parceiro precisa.
            <br />
            <span>Venha ser um parceiro Sedafio!</span>
          </p>
        </p>
      </div>

      <div className="contact-button">
        <Link to="/contato">Contato</Link>
      </div>
    </section>
  </>
);

export default Contato;
