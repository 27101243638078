import React from 'react';
import { Link } from 'gatsby';

import BannerOrcamento2 from '../../../assets/images/banner2-orcamento.png';

import './orcamento2.scss';

const Orcamento2 = () => (
  <>
    <section id="budget" className="container">
      <div className="budget-text">
        <h1>IMPRESSÃO DIGITAL SUBLIMÁTICA</h1>
        <p>
          Oferecemos impressões em diversas configurações para sua necessidade.
          Trabalhamos com impressora Epson Surecolor F9370 para grandes
          formatos. Coloque quantos desenhos precisar, a cobrança será por
          metro.
          <br />
          <br />
          Largura de impressão: 1,58 (máx.)
          <br />
          Pedido mínimo: 50 metros
          <br />
          Estampas variadas
          <br />
          Estampas variadas Arquivos: PSD/ JPEG
        </p>
        <Link to="/orcamento">Orçamento</Link>
      </div>
      <div className="banner-budget">
        <img src={BannerOrcamento2} alt="banner-orcamento" />
      </div>
    </section>
  </>
);

export default Orcamento2;
